import React from 'react';

import Layout from '../layout/index';

import SEO from '../components/seo/index';

import '../assets/styles/pages/faq.scss';

import aboutbg from '../assets/images/about-bg.svg';

import loungeImg from '../assets/images/home/lounge.png';


const Lounge = () => {
    return (
        <>
            <SEO title='Facilites' />
            <Layout>
                <div >
                    <div className="abtimg">
                        <img
                            src={aboutbg}
                            alt="First slide"
                        />
                        <p className="text">Facilities</p>
                    </div>
                </div>
                <div  className="about-sec padding-abt">
                    <div className='col-md-12'>
                        <section className='section'>
                            <div className='container'>
                                <div className='col-md-12'>
                                    <div>
                                        <h3 className="header-tag">Library and Seeker`s Lounge</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className="about-text">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            It has survived not only five centuries, but also the leap into electronic typesetting,
                                            remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            It has survived not only five centuries, but also the leap into electronic typesetting,
                                            remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                            sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
                                            like Aldus PageMaker including versions of Lorem Ipsum.
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div>
                                            <img src={loungeImg} width='569px' height='369px' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </Layout>
        </>
    );
};

export default Lounge;
